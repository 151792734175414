import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Tabs } from "antd";
import { ICheckedForecast, SyncWells, setSyncWells } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { convertConstantsToDatabaseUnits } from "components/arps/utils/convertConstantsToDatabaseUnits";
import TypeWellInputTable from "components/arps/widget/TypeWellEditor/tabs/TypeWellInputTable";
import { useSelectedWellFromStore } from "components/data-analyzer/hooks";

import { DEFAULT_DECLINE_TYPE_SETTING } from "../user-settings/constants/userSetting.constants";
import { useUserSettings } from "../user/hooks";
import ForecastingEditorChangeRecords from "./ForecastingEditorChangeRecords";
import { ForecastingEditorHeader } from "./ForecastingEditorHeader";
import useUpdateForecastWell from "./hooks/updateForecastWell";
import useUpdateForecastWellOnTime from "./hooks/updateForecastWellOnTime";
import useBackfillForecast from "./hooks/useBackfillForecast";
import useFetchForecastWell from "./hooks/useFetchForecastWell";
import usePromoteForecast from "./hooks/usePromoteForecast";
import { ForecastWellStatus, OnTime, UpdateForecastWell } from "./models/forecastWell";

const ARPS_EDITOR_KEY = "1";
const PUBLISHED_RECORDS_KEY = "2";
const ARCHIVED_RECORDS_KEY = "3";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForecastingProps {
  // wellHierarchy: WellHierarchy;
}

const Forecasting: FC<ForecastingProps> = () => {
  const selectedWell = useSelectedWellFromStore();
  const { data: forecastWell } = useFetchForecastWell(selectedWell?.Uwi || "");
  const { mutateAsync: updateForecast } = useUpdateForecastWell();
  const { mutateAsync: updateOnTime } = useUpdateForecastWellOnTime();
  const { mutateAsync: promoteForecast } = usePromoteForecast();
  const { mutateAsync: backfillForecast } = useBackfillForecast();
  const dispatch = useDispatch();

  const syncWells = useSelector((state: RootState) => state.app.syncWells);
  const syncWellsRef = useRef<SyncWells>(syncWells);

  const role = useSelector((state: RootState) => state.auth.user?.role);
  const isAdminOrPowerUser = role === "Admin" || role === "PowerUser";

  const [activeTab, setActiveTab] = useState(ARPS_EDITOR_KEY);

  const { declineType: declineTypeSetting } = useUserSettings();
  const declineType = declineTypeSetting?.decline ?? DEFAULT_DECLINE_TYPE_SETTING;
  const toExpectedBackendProductName = (product: string) => {
    switch (product.toUpperCase()) {
      case "SHRINKAGE":
        return "total shrinkage inlet";
      case "SALES GAS":
        return "sales gas";
      case "C2":
        return "ethYieldSales";
      case "C3":
        return "propYieldSales";
      case "C4":
        return "butYieldSales";
      default:
        return product;
    }
  };

  useEffect(() => {
    syncWellsRef.current = syncWells;
  }, [syncWells]);

  useEffect(() => {
    if (!forecastWell || !syncWellsRef.current) {
      return;
    }
    dispatch(
      setSyncWells({
        ...syncWellsRef.current,
        activeWells: [forecastWell.uwi]
      })
    );
  }, [forecastWell]);

  const handleForecastChange = async (item: ICheckedForecast) => {
    try {
      await updateForecast({
        uwi: forecastWell.uwi,
        update: {
          entityName: forecastWell.entityName,
          forecast: {
            segments: item.arps.map((seg) => ({
              ...seg,
              product: toExpectedBackendProductName(seg.product)
            })),
            onTimeArray: item.onTimeArray,
            constants: convertConstantsToDatabaseUnits(item.constants).map((fc) => ({
              ...fc,
              product: toExpectedBackendProductName(fc.product)
            }))
          }
        } as UpdateForecastWell
      });
    } catch (error) {
      toast.error("Failed to update forecast");
    }
  };

  const handleArchiveForecast = async () => {
    try {
      await promoteForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Archived
      });
    } catch (error) {
      toast.error("Failed to archive forecast");
    }
  };

  const handlePublishForecast = async () => {
    try {
      await promoteForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Published
      });
    } catch (error) {
      toast.error("Failed to publish forecast");
    }
  };

  const handleDownloadPublishedForecast = async () => {
    try {
      await backfillForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Published
      });
    } catch (error) {
      toast.error("Failed to populate forecast from published version");
    }
  };

  const handleUnarchiveForecast = async () => {
    try {
      await backfillForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Archived
      });
    } catch (error) {
      toast.error("Failed to populate forecast from archived version");
    }
  };

  const handleOnTimeChange = async (onTime: OnTime[]) => {
    try {
      await updateOnTime({
        uwi: selectedWell?.Uwi,
        update: {
          forecast: {
            onTimeArray: onTime
          }
        } as UpdateForecastWell
      });
    } catch {
      toast.error("Unable to update on-time");
    }
  };

  return (
    <RootContainer>
      <Header>
        <ForecastingEditorHeader
          entityName={forecastWell?.entityName}
          handleArchiveForecast={handleArchiveForecast}
          handlePublishForecast={handlePublishForecast}
          handleDownloadPublishedForecast={handleDownloadPublishedForecast}
          handleUnarchiveForecast={handleUnarchiveForecast}
          isWidget={true}
          saveStatus={true}
          hideButtons={!isAdminOrPowerUser}
        />
      </Header>
      {forecastWell && (
        <EditorWrapper>
          <StyledTabs
            defaultActiveKey={ARPS_EDITOR_KEY}
            activeKey={activeTab}
            onTabClick={setActiveTab}
            items={[
              {
                key: ARPS_EDITOR_KEY,
                label: "Forecast",
                children: (
                  <TypeWellInputTable
                    declineType={declineType}
                    selectedTypeWellTitle={""}
                    kind={"Forecasting"}
                    arps={
                      (forecastWell?.forecast as unknown as ICheckedForecast) ??
                      ({ title: forecastWell?.entityName } as ICheckedForecast)
                    }
                    selectedTypeWellRescat={"P+PDP"}
                    onOnTimeChange={handleOnTimeChange}
                    onChange={handleForecastChange}
                  />
                )
              },
              {
                key: PUBLISHED_RECORDS_KEY,
                label: "Published Records",
                children: (
                  <>
                    <ForecastingEditorChangeRecords
                      forecastId={forecastWell?.uwi}
                      status={ForecastWellStatus.Published}
                    />
                  </>
                )
              },
              {
                key: ARCHIVED_RECORDS_KEY,
                label: "Archived Records",
                children: (
                  <>
                    <ForecastingEditorChangeRecords
                      forecastId={forecastWell?.uwi}
                      status={ForecastWellStatus.Archived}
                    />
                  </>
                )
              }
            ]}
          />
        </EditorWrapper>
      )}
      {!forecastWell && <div>Select a well from Well Hierarchy to start...</div>}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  height: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  overflow: auto;
`;

const EditorWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: visible;
`;

const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-tabs-content-holder {
    height: 100%;

    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const Header = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 100%;
  // grid-template-columns: 36px 1fr;
  height: 48px;
  margin: 0; // Reset margin
  padding: 0; // Reset padding
`;
export default Forecasting;
